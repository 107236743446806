<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 22C7 22 5.75 22 5.75 20.75C5.75 19.5 7 15.75 12 15.75C17 15.75 18.25 19.5 18.25 20.75C18.25 22 17 22 17 22H7Z" fill="currentColor"/>
<path d="M12 14.5625C13.7259 14.5625 15.125 13.1634 15.125 11.4375C15.125 9.71162 13.7259 8.31251 12 8.31251C10.2741 8.31251 8.875 9.71162 8.875 11.4375C8.875 13.1634 10.2741 14.5625 12 14.5625Z" fill="currentColor"/>
<path d="M4.5 3.25C3.11929 3.25 2 4.36929 2 5.75V17.625C2 18.6605 2.83947 19.5 3.875 19.5H4.6911C4.91021 18.7169 5.33136 17.818 6.02347 17H3.25V5.75C3.25 5.05964 3.80964 4.5 4.5 4.5H19.5C20.1904 4.5 20.75 5.05964 20.75 5.75V17H17.9765C18.6686 17.818 19.0898 18.7169 19.3089 19.5H20.125C21.1605 19.5 22 18.6605 22 17.625V5.75C22 4.36929 20.8807 3.25 19.5 3.25H4.5Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "Testi",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>