<template>
  <svg :width="width" :height="height" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9733 26.9725L23.2933 21.4658C23.5047 21.3119 23.6782 21.1118 23.8007 20.8808C23.9231 20.6498 23.9913 20.3938 24 20.1325V17.0525C24 16.5963 23.8188 16.1588 23.4962 15.8363C23.1737 15.5137 22.7362 15.3325 22.28 15.3325H1.74666C1.51807 15.3271 1.29073 15.3678 1.07814 15.452C0.865552 15.5362 0.672051 15.6622 0.509128 15.8227C0.346206 15.9831 0.217183 16.1746 0.129722 16.3859C0.0422609 16.5972 -0.00185457 16.8239 -5.20821e-06 17.0525V20.0792C0.00868509 20.3405 0.0768474 20.5964 0.199299 20.8275C0.321751 21.0585 0.495269 21.2586 0.706661 21.4125L10.92 26.9192C11.2101 27.1498 11.5673 27.2797 11.9377 27.2893C12.3082 27.2989 12.6717 27.1877 12.9733 26.9725Z"
      fill="currentColor"
    />
    <path
      d="M9.82663 4.28105L11.3866 1.17438C11.4549 1.05825 11.5523 0.961961 11.6693 0.895061C11.7862 0.828162 11.9186 0.792969 12.0533 0.792969C12.188 0.792969 12.3204 0.828162 12.4373 0.895061C12.5543 0.961961 12.6517 1.05825 12.72 1.17438L14.2666 4.32105C14.3206 4.43194 14.4012 4.52773 14.5013 4.59985C14.6013 4.67198 14.7177 4.7182 14.84 4.73438L18.28 5.26772C18.4276 5.28346 18.5674 5.34211 18.6821 5.43642C18.7968 5.53073 18.8813 5.65657 18.9253 5.7984C18.9693 5.94022 18.9707 6.09182 18.9295 6.23446C18.8883 6.37711 18.8061 6.50455 18.6933 6.60105L16.1866 8.97438C16.0969 9.05829 16.0293 9.16303 15.9898 9.27932C15.9502 9.39561 15.94 9.51986 15.96 9.64105L16.52 13.0677C16.5442 13.2079 16.5286 13.3521 16.4749 13.4839C16.4212 13.6157 16.3316 13.7298 16.2163 13.8131C16.101 13.8965 15.9646 13.9458 15.8226 13.9554C15.6807 13.9651 15.5388 13.9347 15.4133 13.8677L12.3466 12.2277C12.2373 12.1716 12.1162 12.1423 11.9933 12.1423C11.8704 12.1423 11.7493 12.1716 11.64 12.2277L8.54663 13.8277C8.4204 13.8922 8.27866 13.9201 8.13741 13.9082C7.99617 13.8964 7.86105 13.8453 7.74732 13.7607C7.6336 13.6761 7.5458 13.5613 7.49384 13.4295C7.44188 13.2976 7.42784 13.1538 7.4533 13.0144L8.06663 9.60105C8.08711 9.47891 8.07846 9.35367 8.04139 9.23551C8.00432 9.11734 7.93987 9.0096 7.8533 8.92105L5.3333 6.46772C5.21953 6.36956 5.13741 6.23988 5.09732 6.09507C5.05722 5.95026 5.06096 5.79681 5.10805 5.65412C5.15513 5.51142 5.24346 5.38589 5.36187 5.29339C5.48028 5.20088 5.62345 5.14555 5.7733 5.13438L9.2533 4.66772C9.37364 4.65656 9.48923 4.6153 9.58943 4.54772C9.68962 4.48015 9.7712 4.38844 9.82663 4.28105Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CareerApplicationIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
