<template>
  
<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.62695 13.25C8.66249 13.25 9.50195 12.4105 9.50195 11.375C9.50195 10.3395 8.66249 9.5 7.62695 9.5C6.59142 9.5 5.75195 10.3395 5.75195 11.375C5.75195 12.4105 6.59142 13.25 7.62695 13.25Z" fill="currentColor"/>
<path d="M19.502 18.25C19.502 19.6307 18.3827 20.75 17.002 20.75H4.50195C3.12124 20.75 2.00195 19.6307 2.00195 18.25V8.25C2.00195 6.86993 3.1202 5.75104 4.50003 5.75C4.50003 4.36929 5.61931 3.25 7.00003 3.25H19.5C20.8807 3.25 22 4.36929 22 5.75V15.75C22 17.1301 20.8818 18.249 19.502 18.25ZM19.5 4.5H7.00003C6.30967 4.5 5.75003 5.05964 5.75003 5.75L17.002 5.75C18.3827 5.75 19.502 6.86929 19.502 8.25V17C20.1914 16.999 20.75 16.4397 20.75 15.75V5.75C20.75 5.05964 20.1904 4.5 19.5 4.5ZM4.50195 7C3.8116 7 3.25195 7.55964 3.25195 8.25V18.25L6.56001 15.3081C6.77068 15.0974 7.10075 15.0647 7.34864 15.23L10.6723 17.4458L15.31 12.8081C15.5002 12.6179 15.7908 12.5707 16.0315 12.691L18.252 15.125V8.25C18.252 7.55964 17.6923 7 17.002 7H4.50195Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: "MainBanner",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
