<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.8043 12.9093C7.74989 12.9093 4.33911 15.3936 4.33911 18.4463M10.8043 10.1442C11.5361 10.1457 12.2519 9.93029 12.8612 9.52511C13.4706 9.11993 13.9462 8.5432 14.2278 7.86781C14.4385 7.36282 14.5344 6.81736 14.5085 6.27081C14.4827 5.72426 14.3358 5.19028 14.0784 4.70741C13.8211 4.22455 13.4597 3.80486 13.0204 3.47867C12.5811 3.15248 12.0749 2.92792 11.5382 2.82121C11.0017 2.71422 10.4481 2.72752 9.9173 2.86016C9.3865 2.99279 8.89172 3.24146 8.46856 3.58826C8.0454 3.93506 7.7044 4.37137 7.4701 4.86578C7.2358 5.36018 7.11403 5.90039 7.11355 6.4475C7.11333 7.42697 7.50191 8.36647 8.19395 9.05962C8.88598 9.75276 9.82486 10.1429 10.8043 10.1442Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.3389 13.5569L13.0967 17.7898M13.0967 13.5662L17.3389 17.7992" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>

</template>

<script>
export default {
  name: "NonUser",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
