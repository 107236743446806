<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 12C11.5711 12 13.25 10.3211 13.25 8.25C13.25 6.17893 11.5711 4.5 9.5 4.5C7.42893 4.5 5.75 6.17893 5.75 8.25C5.75 10.3211 7.42893 12 9.5 12Z"
      fill="currentColor"
    />
    <path
      d="M3.25 19.5C3.25 19.5 2 19.5 2 18.25C2 17 3.25 13.25 9.5 13.25C15.75 13.25 17 17 17 18.25C17 19.5 15.75 19.5 15.75 19.5H3.25Z"
      fill="currentColor"
    />
    <path
      d="M15.75 6.375C15.75 6.02982 16.0298 5.75 16.375 5.75H21.375C21.7202 5.75 22 6.02982 22 6.375C22 6.72018 21.7202 7 21.375 7H16.375C16.0298 7 15.75 6.72018 15.75 6.375Z"
      fill="currentColor"
    />
    <path
      d="M16.375 9.5C16.0298 9.5 15.75 9.77982 15.75 10.125C15.75 10.4702 16.0298 10.75 16.375 10.75H21.375C21.7202 10.75 22 10.4702 22 10.125C22 9.77982 21.7202 9.5 21.375 9.5H16.375Z"
      fill="currentColor"
    />
    <path
      d="M18.875 13.25C18.5298 13.25 18.25 13.5298 18.25 13.875C18.25 14.2202 18.5298 14.5 18.875 14.5H21.375C21.7202 14.5 22 14.2202 22 13.875C22 13.5298 21.7202 13.25 21.375 13.25H18.875Z"
      fill="currentColor"
    />
    <path
      d="M18.875 17C18.5298 17 18.25 17.2798 18.25 17.625C18.25 17.9702 18.5298 18.25 18.875 18.25H21.375C21.7202 18.25 22 17.9702 22 17.625C22 17.2798 21.7202 17 21.375 17H18.875Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Testi",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
