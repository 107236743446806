<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin: 40px 0">{{ name }}</h1>
      <div class="form-ctn">
        <div class="content-form">
        <p>Nama</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          class="form-input mt-2"
          placeholder="Masukkan Nama"
          v-model="form.name"
          dense
          clearable
        ></v-text-field>
      </div>
        <div class="content-form">
          <p>Batch</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.batch_number"
            @input="
              () => {
                if (form.batch_number < 0) {
                  form.batch_number = 1;
                }
              }
            "
            class="form-input"
            placeholder="Batch"
            type="number"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Total Sesi</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.session"
            @input="
              () => {
                if (form.session < 0) {
                  form.session = 0;
                }
              }
            "
            class="form-input"
            placeholder="Total Sesi"
            type="number"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Batas Peserta</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.total_audience"
            @input="
              () => {
                if (form.total_audience < 0) {
                  form.total_audience = 1;
                }
              }
            "
            class="form-input"
            placeholder="Batas Peserta"
            type="number"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Durasi</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="3" class="pt-2"> Mulai </v-col>
                <v-col cols="8">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="datetime-local"
                    v-model="form.start_date"
                    class="form-input"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="pt-2"> Selesai </v-col>
                <v-col cols="8">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="datetime-local"
                    v-model="form.end_date"
                    class="form-input"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="content-form">
          <p>Deskripsi</p>
          <v-textarea
            placeholder="Caption"
            :disabled="isWatch"
            outlined
            rows="3"
            v-model="form.description"
            clearable
            class="form-input"
          ></v-textarea>
        </div>
        <div class="content-form">
          <p>Kelas</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="classes"
            placeholder="Pilih Kelas"
            :item-text="'name'"
            :item-value="'id'"
            v-model="form.class_id"
            solo
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Kelas Kategori</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="class_categories"
            placeholder="Pilih Kelas Kategori"
            :item-text="'name'"
            :item-value="'id'"
            v-model="form.category_id"
            solo
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Status</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="statuses"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.is_active"
            solo
          >
          </v-select>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button
            name="PUBLISH"
            width="260px"
            @click="onSumbmit"
            :disabled="!validData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormSchedule",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      statuses: [
        {name: "Aktif", value: true},
        {name: "Non Aktif", value: false},
      ],
      classes: [],
      class_categories: [],
      form: {
        batch_number: 1,
        total_audience: 1,
        list_schedule: [],
        session: 0,
        category_id: 0,
        class_id: 0,
        start_date: "",
        end_date: "",
        days: "",
        hours: "",
        name: "",
        is_active: false,
      },
    };
  },
  components: {
    Button,
    Loading,
    Popup
  },
  computed: {
    validData() {
      return (
        this.form.batch_number &&
        this.form.start_date &&
        this.form.end_date &&
        this.form.name
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getSchedule();
    },
    async getSchedule() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/schedule/" + this.id);
        const data = resp.data.data.schedule;
        this.form = {
          ...data,
          start_date: moment(data.start_date).format("YYYY-MM-DDThh:mm:ss"),
          end_date: moment(data.end_date).format("YYYY-MM-DDThh:mm:ss")
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async getClass() {
      try {
          const resp = await this.$web_http.get("/v1/class");
          this.classes = resp.data.data.classes
      } catch (err) {
        console.log(err);
      }
    },
    async getClassCategory() {
      try {
          const resp = await this.$crm_http.get("/v1/class-category");
          this.class_categories = resp.data.data.class_categories
      } catch (err) {
        console.log(err);
      }
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          await this.$crm_http.put("/v1/schedule/" + this.id, {
            ...this.form,
            batch_number: Number(this.form.batch_number),
            category_id: Number(this.form.category_id),
            session: Number(this.form.session),
            total_audience: Number(this.form.total_audience),
            class_id: Number(this.form.class_id),
            days: moment(this.form.start_date).format("DD-MM-YYY") + " - " + moment(this.form.end_date).format("DD-MM-YYY"),
            hours: moment(this.form.start_date).format("HH:mm") + " - " + moment(this.form.end_date).format("HH:mm"),
        });
        } else {
          await this.$crm_http.post("/v1/schedule", {
            ...this.form,
            batch_number: Number(this.form.batch_number),
            category_id: Number(this.form.category_id),
            session: Number(this.form.session),
            total_audience: Number(this.form.total_audience),
            class_id: Number(this.form.class_id),
            days: "",
            hours: ""
        });
        }
        setTimeout(() => {
          this.$router.push("/schedule");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
    this.getClass()
    this.getClassCategory()
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  background-color: white;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: absolute;
  top: 0;
  right: -50px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
.form-ctn {
  padding: 0 40px;
}
.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
