<template>
    <svg :width="width"
    :height="height" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5 2H4.5C3.11929 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2ZM3.25 6.82143C3.25 6.22969 3.80964 5.75 4.5 5.75H19.5C20.1904 5.75 20.75 6.22969 20.75 6.82143V19.6786C20.75 20.2703 20.1904 20.75 19.5 20.75H4.5C3.80964 20.75 3.25 20.2703 3.25 19.6786V6.82143Z" 
fill="currentColor"/>
<path d="M10.125 10.75C10.8154 10.75 11.375 10.1904 11.375 9.5C11.375 8.80964 10.8154 8.25 10.125 8.25C9.43464 8.25 8.875 8.80964 8.875 9.5C8.875 10.1904 9.43464 10.75 10.125 10.75Z" 
fill="currentColor"/>
<path d="M13.875 10.75C14.5654 10.75 15.125 10.1904 15.125 9.5C15.125 8.80964 14.5654 8.25 13.875 8.25C13.1846 8.25 12.625 8.80964 12.625 9.5C12.625 10.1904 13.1846 10.75 13.875 10.75Z" 
fill="currentColor"/>
<path d="M17.625 10.75C18.3154 10.75 18.875 10.1904 18.875 9.5C18.875 8.80964 18.3154 8.25 17.625 8.25C16.9346 8.25 16.375 8.80964 16.375 9.5C16.375 10.1904 16.9346 10.75 17.625 10.75Z" 
fill="currentColor"/>
<path d="M6.375 14.5C7.06536 14.5 7.625 13.9404 7.625 13.25C7.625 12.5596 7.06536 12 6.375 12C5.68464 12 5.125 12.5596 5.125 13.25C5.125 13.9404 5.68464 14.5 6.375 14.5Z" 
fill="currentColor"/>
<path d="M10.125 14.5C10.8154 14.5 11.375 13.9404 11.375 13.25C11.375 12.5596 10.8154 12 10.125 12C9.43464 12 8.875 12.5596 8.875 13.25C8.875 13.9404 9.43464 14.5 10.125 14.5Z" 
fill="currentColor"/>
<path d="M13.875 14.5C14.5654 14.5 15.125 13.9404 15.125 13.25C15.125 12.5596 14.5654 12 13.875 12C13.1846 12 12.625 12.5596 12.625 13.25C12.625 13.9404 13.1846 14.5 13.875 14.5Z" 
fill="#currentColor"
stroke="currentColor"/>
<path d="M17.625 14.5C18.3154 14.5 18.875 13.9404 18.875 13.25C18.875 12.5596 18.3154 12 17.625 12C16.9346 12 16.375 12.5596 16.375 13.25C16.375 13.9404 16.9346 14.5 17.625 14.5Z" 
fill="#currentColor"
stroke="currentColor"/>
<path d="M6.375 18.25C7.06536 18.25 7.625 17.6904 7.625 17C7.625 16.3096 7.06536 15.75 6.375 15.75C5.68464 15.75 5.125 16.3096 5.125 17C5.125 17.6904 5.68464 18.25 6.375 18.25Z" 
fill="#currentColor"
stroke="currentColor"/>
<path d="M10.125 18.25C10.8154 18.25 11.375 17.6904 11.375 17C11.375 16.3096 10.8154 15.75 10.125 15.75C9.43464 15.75 8.875 16.3096 8.875 17C8.875 17.6904 9.43464 18.25 10.125 18.25Z" 
fill="#currentColor"
stroke="currentColor"/>
<path d="M13.875 18.25C14.5654 18.25 15.125 17.6904 15.125 17C15.125 16.3096 14.5654 15.75 13.875 15.75C13.1846 15.75 12.625 16.3096 12.625 17C12.625 17.6904 13.1846 18.25 13.875 18.25Z" 
fill="#currentColor"
stroke="currentColor"/>
</svg>
</template>

<script>
export default {
    name: "Schedule",
    props: {
        width: {
            type: Number,
            default: 25
        },
        height: {
            type: Number,
            default: 25
        },
    }
}
</script>