<template>
    
<!-- <svg :width="width"
    :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.3463 9.33338L19.013 4.26671C18.1256 3.65904 17.0752 3.33386 15.9997 3.33386C14.9241 3.33386 13.8737 3.65904 12.9863 4.26671L5.65301 9.33338C4.93789 9.82307 4.35288 10.4797 3.94864 11.2463C3.54439 12.013 3.33309 12.8667 3.33301 13.7334V23.3334C3.33301 24.7479 3.89491 26.1044 4.89511 27.1046C5.8953 28.1048 7.25185 28.6667 8.66634 28.6667C9.01996 28.6667 9.3591 28.5262 9.60915 28.2762C9.8592 28.0261 9.99967 27.687 9.99967 27.3334V19.3334C9.99967 18.4493 10.3509 17.6015 10.976 16.9764C11.6011 16.3512 12.449 16 13.333 16H18.6663C19.5504 16 20.3982 16.3512 21.0234 16.9764C21.6485 17.6015 21.9997 18.4493 21.9997 19.3334V27.3334C21.9997 27.687 22.1402 28.0261 22.3902 28.2762C22.6402 28.5262 22.9794 28.6667 23.333 28.6667C24.7475 28.6667 26.104 28.1048 27.1042 27.1046C28.1044 26.1044 28.6663 24.7479 28.6663 23.3334V13.68C28.6577 12.8224 28.4422 11.9794 28.0384 11.2227C27.6345 10.4661 27.0541 9.81795 26.3463 9.33338Z" fill="white"/>
<path d="M18 18H14C12.8954 18 12 18.8954 12 20V26.6C12 27.7046 12.8954 28.6 14 28.6H18C19.1046 28.6 20 27.7046 20 26.6V20C20 18.8954 19.1046 18 18 18Z" fill="white"/>
</svg> -->
<svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.25 7.5H14.75C16.25 7.5 17 6.75 17 5.25V3.75C17 2.25 16.25 1.5 14.75 1.5H13.25C11.75 1.5 11 2.25 11 3.75V5.25C11 6.75 11.75 7.5 13.25 7.5Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.25 16.5H5.75C7.25 16.5 8 15.75 8 14.25V12.75C8 11.25 7.25 10.5 5.75 10.5H4.25C2.75 10.5 2 11.25 2 12.75V14.25C2 15.75 2.75 16.5 4.25 16.5Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 7.5C6.65685 7.5 8 6.15685 8 4.5C8 2.84315 6.65685 1.5 5 1.5C3.34315 1.5 2 2.84315 2 4.5C2 6.15685 3.34315 7.5 5 7.5Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 16.5C15.6569 16.5 17 15.1569 17 13.5C17 11.8431 15.6569 10.5 14 10.5C12.3431 10.5 11 11.8431 11 13.5C11 15.1569 12.3431 16.5 14 16.5Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</template>
<script>
export default {
  name: 'HomeIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>