<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6666 4H7.33331C6.27245 4 5.25503 4.42143 4.50489 5.17157C3.75474 5.92172 3.33331 6.93913 3.33331 8V24C3.33331 25.0609 3.75474 26.0783 4.50489 26.8284C5.25503 27.5786 6.27245 28 7.33331 28H24.6666C25.7275 28 26.7449 27.5786 27.4951 26.8284C28.2452 26.0783 28.6666 25.0609 28.6666 24V8C28.6666 6.93913 28.2452 5.92172 27.4951 5.17157C26.7449 4.42143 25.7275 4 24.6666 4ZM7.33331 8C7.33331 7.82319 7.40355 7.65362 7.52857 7.5286C7.6536 7.40357 7.82317 7.33333 7.99998 7.33333H13.3333C13.5101 7.33333 13.6797 7.40357 13.8047 7.5286C13.9297 7.65362 14 7.82319 14 8C14 8.17681 13.9297 8.34638 13.8047 8.4714C13.6797 8.59643 13.5101 8.66667 13.3333 8.66667H7.99998C7.82317 8.66667 7.6536 8.59643 7.52857 8.4714C7.40355 8.34638 7.33331 8.17681 7.33331 8ZM14.6666 24.6667C14.6666 24.8435 14.5964 25.013 14.4714 25.1381C14.3464 25.2631 14.1768 25.3333 14 25.3333H11.3333C11.1565 25.3333 10.9869 25.2631 10.8619 25.1381C10.7369 25.013 10.6666 24.8435 10.6666 24.6667V20.6667C10.6666 20.4899 10.7369 20.3203 10.8619 20.1953C10.9869 20.0702 11.1565 20 11.3333 20H14C14.1768 20 14.3464 20.0702 14.4714 20.1953C14.5964 20.3203 14.6666 20.4899 14.6666 20.6667V24.6667ZM20 24.6667C20 24.8435 19.9297 25.013 19.8047 25.1381C19.6797 25.2631 19.5101 25.3333 19.3333 25.3333H16.6666C16.4898 25.3333 16.3203 25.2631 16.1952 25.1381C16.0702 25.013 16 24.8435 16 24.6667V18C16 17.8232 16.0702 17.6536 16.1952 17.5286C16.3203 17.4036 16.4898 17.3333 16.6666 17.3333H19.3333C19.5101 17.3333 19.6797 17.4036 19.8047 17.5286C19.9297 17.6536 20 17.8232 20 18V24.6667ZM25.3333 24.6667C25.3333 24.8435 25.2631 25.013 25.1381 25.1381C25.013 25.2631 24.8435 25.3333 24.6666 25.3333H22C21.8232 25.3333 21.6536 25.2631 21.5286 25.1381C21.4035 25.013 21.3333 24.8435 21.3333 24.6667V15.3333C21.3333 15.1565 21.4035 14.987 21.5286 14.8619C21.6536 14.7369 21.8232 14.6667 22 14.6667H24.6666C24.8435 14.6667 25.013 14.7369 25.1381 14.8619C25.2631 14.987 25.3333 15.1565 25.3333 15.3333V24.6667Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: "SectionTwo",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
