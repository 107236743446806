<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6666 4H7.33325C6.27239 4 5.25497 4.42143 4.50482 5.17157C3.75468 5.92172 3.33325 6.93913 3.33325 8V24C3.33325 25.0609 3.75468 26.0783 4.50482 26.8284C5.25497 27.5786 6.27239 28 7.33325 28H24.6666C25.7274 28 26.7449 27.5786 27.495 26.8284C28.2452 26.0783 28.6666 25.0609 28.6666 24V8C28.6666 6.93913 28.2452 5.92172 27.495 5.17157C26.7449 4.42143 25.7274 4 24.6666 4ZM19.3333 10.6667C19.8607 10.6667 20.3762 10.8231 20.8148 11.1161C21.2533 11.4091 21.5951 11.8256 21.7969 12.3128C21.9988 12.8001 22.0516 13.3363 21.9487 13.8536C21.8458 14.3709 21.5918 14.846 21.2189 15.219C20.8459 15.5919 20.3708 15.8459 19.8535 15.9488C19.3362 16.0517 18.8 15.9988 18.3128 15.797C17.8255 15.5952 17.409 15.2534 17.116 14.8149C16.823 14.3763 16.6666 13.8608 16.6666 13.3333C16.6666 12.6261 16.9475 11.9478 17.4476 11.4477C17.9477 10.9476 18.626 10.6667 19.3333 10.6667ZM7.33325 8C7.33325 7.82319 7.40349 7.65362 7.52851 7.5286C7.65354 7.40357 7.82311 7.33333 7.99992 7.33333H13.3333C13.5101 7.33333 13.6796 7.40357 13.8047 7.5286C13.9297 7.65362 13.9999 7.82319 13.9999 8C13.9999 8.17681 13.9297 8.34638 13.8047 8.4714C13.6796 8.59643 13.5101 8.66667 13.3333 8.66667H7.99992C7.82311 8.66667 7.65354 8.59643 7.52851 8.4714C7.40349 8.34638 7.33325 8.17681 7.33325 8ZM23.5466 25.3333H15.1199C14.844 25.3572 14.5664 25.3135 14.3111 25.2061C14.0559 25.0987 13.8306 24.9307 13.6547 24.7168C13.4788 24.5029 13.3577 24.2493 13.3017 23.9781C13.2457 23.7069 13.2565 23.4261 13.3333 23.16L14.4133 20.1867C14.8197 19.223 15.5088 18.4052 16.3895 17.841C17.2702 17.2769 18.3012 16.993 19.3466 17.0267C20.3919 16.993 21.423 17.2769 22.3037 17.841C23.1844 18.4052 23.8734 19.223 24.2799 20.1867L25.3333 23.16C25.41 23.4261 25.4209 23.7069 25.3648 23.9781C25.3088 24.2493 25.1877 24.5029 25.0118 24.7168C24.8359 24.9307 24.6106 25.0987 24.3554 25.2061C24.1001 25.3135 23.8225 25.3572 23.5466 25.3333Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SectionOne",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
