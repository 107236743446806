<template>
  
<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 8.0625V9.1875H8.25V8.0625H7Z" fill="currentColor"/>
<path d="M15.75 8.0625V9.1875H17V8.0625H15.75Z" fill="currentColor"/>
<path d="M7 10.3125V11.4375H8.25V10.3125H7Z" fill="currentColor"/>
<path d="M15.75 10.3125V11.4375H17V10.3125H15.75Z" fill="currentColor"/>
<path d="M7 12.5625V13.6875H8.25V12.5625H7Z" fill="currentColor"/>
<path d="M15.75 12.5625V13.6875H17V12.5625H15.75Z" fill="currentColor"/>
<path d="M7 14.8125V15.9375H8.25V14.8125H7Z" fill="currentColor"/>
<path d="M15.75 14.8125V15.9375H17V14.8125H15.75Z" fill="currentColor"/>
<path d="M3.875 5.75C2.83947 5.75 2 6.58947 2 7.625V9.5C2 9.84518 2.27982 10.125 2.625 10.125C3.66053 10.125 4.5 10.9645 4.5 12C4.5 13.0355 3.66053 13.875 2.625 13.875C2.27982 13.875 2 14.1548 2 14.5V16.375C2 17.4105 2.83947 18.25 3.875 18.25H20.125C21.1605 18.25 22 17.4105 22 16.375V14.5C22 14.1548 21.7202 13.875 21.375 13.875C20.3395 13.875 19.5 13.0355 19.5 12C19.5 10.9645 20.3395 10.125 21.375 10.125C21.7202 10.125 22 9.84518 22 9.5V7.625C22 6.58947 21.1605 5.75 20.125 5.75H3.875ZM3.25 7.625C3.25 7.27982 3.52982 7 3.875 7H20.125C20.4702 7 20.75 7.27982 20.75 7.625V8.93751C19.3236 9.22706 18.25 10.4882 18.25 12C18.25 13.5118 19.3236 14.7729 20.75 15.0625V16.375C20.75 16.7202 20.4702 17 20.125 17H3.875C3.52982 17 3.25 16.7202 3.25 16.375V15.0625C4.6764 14.7729 5.75 13.5118 5.75 12C5.75 10.4882 4.6764 9.22706 3.25 8.93751V7.625Z" fill="currentColor"/>
</svg>

</template>
<script>
export default {
  name: "CloseX",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
};
</script>
