<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah kamu yakin ingin menghapus applicant ini?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button name="YA, HAPUS DATA" width="100%" @click="onDelete" />
        <Button
          name="TIDAK, KEMBALI"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
      </div>
    </Modal>
    <div class="container-box">
      <h1>Career Application</h1>
      <!-- <div class="action-head">
        <div class="f-search">
          <div class="f-search-ic">
            <img src="/img/icons/search.png" style="width: 100%" alt="Search Ic" />
          </div>
          <input v-model="search" type="text" class="f-input" placeholder="Cari" @keyup="getCareerApplication" />
        </div>
      </div> -->
    </div>
    <v-data-table :headers="headers" :items="careerApplication" sort-by="calories" class="elevation-1 mt-10 table" hide-default-footer>
      <template v-slot:item.status="{ item }">
        <v-select
            class="form-input"
            :items="statuses"
            v-model="item.status"
            @input="updateStatus(item)"
            solo
            :item-text="'text'"
            :item-value="'value'"
          ></v-select>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="watchItem(item)"> mdi-eye </v-icon>
        <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

    </v-data-table>

    <v-row justify="center" v-if="total">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination v-model="currentPage" class="my-4" :length="total" @input="getCareerApplication"></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import moment from 'moment';
import Button from "../../../components/Button.vue";

import Loading from '@/components/ilustration/Loading.vue';
import getAuthentification from '@/utils/badrequest.js';

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      isLoading: false,
      total: 0,
      search: '',
      id: 0,
      statuses: [
        {
          text: 'Terkirim',
          value: 'SUBMITTED'
        },
        {
          text: 'Di Proses',
          value: 'PROCESS'
        },
        {
          text: 'Di Terima',
          value: 'APPROVE'
        },
        {
          text: 'Di Tolak',
          value: 'REJECT'
        },
      ],
      headers: [
        { text: 'NAMA LENGKAP', value: 'full_name', width: 280 },
        { text: 'POSITION', value: 'position', width: 150 },
        { text: 'PERUSAHAAN', value: 'company', width: 320 },
        { text: 'STATUS', value: 'status', width: 180 },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      careerApplication: [],
    };
  },
  components: {
    Loading,
    Modal,
    Button
  },
  methods: {
    async getCareerApplication() {
      try {
        const response = await this.$crm_http.get(`/v1/career-application?career_id=${this.search}&page=${this.currentPage}&per_page=10`);
        this.careerApplication = response.data.data.career_applications
        const t = parseInt(response.data.data.total);
        this.total = Math.floor(t / 10 + (t % 10 === 0 ? 0 : 1));

        this.getCareerById();
      } catch (err) {
        console.error(err.response);
      }
    },
    async updateStatus(item) {
      try {
        await this.$crm_http.put(`/v1/career-application/${item.id}?status=${item.status}`);
      } catch (err) {
        console.error(err.response);
      }
    },
    selectedColor(status) {
      if (status === 'SUBMITTED') return 'blue'
      if (status === 'PROCESS') return 'yellow'
      if (status === 'APPROVE') return 'green'
      return 'red'
    },
    async getCareerById() {
      this.isLoading = true;
      for (let i = 0; i < this.careerApplication.length; i++) {
        try {
          const careerId = this.careerApplication[i].career_id;
          const response = await this.$crm_http.get(`/v1/career/${careerId}`);
          const data = response.data.data.career;

          this.$set(this.careerApplication[i], 'position', data.position);
          this.$set(this.careerApplication[i], 'company', data.company);
          this.$set(this.careerApplication[i], 'location', data.location);
        } catch (error) {
          console.error(error);
        }
      }
      this.isLoading = false;
    },
    watchItem(item) {
      this.$router.push('/career-application/watch/' + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id  
    },
    async onDelete(){
      this.isLoading = true
      try {
        await this.$web_http.delete("/v1/career-application/" + this.id)        
        this.isModal = false
        this.isLoading = false
        this.getCareerApplication()
      } catch (err) {
        console.log(err)
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD');
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getCareerApplication();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
</style>

