<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 12C19.7596 12.0017 19.5214 12.0468 19.297 12.133L16.899 10.17C16.958 9.956 17 9.734 17 9.5C17 8.114 15.886 7 14.5 7C13.114 7 12 8.114 12 9.5C12 9.896 12.1 10.265 12.262 10.597L9.353 14.035C9.23656 14.0132 9.11845 14.0015 9 14C8.821 14 8.652 14.03 8.488 14.074L5.925 11.511C5.97 11.348 6 11.179 6 11C6 9.892 5.108 9 4 9C2.892 9 2 9.892 2 11C2 12.108 2.892 13 4 13C4.179 13 4.348 12.97 4.512 12.926L7.075 15.489C7.02693 15.6551 7.0017 15.8271 7 16C7 17.108 7.892 18 9 18C10.108 18 11 17.108 11 16C11 15.763 10.952 15.54 10.877 15.329L13.79 11.887C14.017 11.953 14.252 12 14.5 12C14.8946 11.9978 15.2831 11.9015 15.633 11.719L18.032 13.682C18.0132 13.787 18.0025 13.8933 18 14C18 15.108 18.892 16 20 16C21.108 16 22 15.108 22 14C22 12.892 21.108 12 20 12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Statistic",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
