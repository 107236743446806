import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import helpers from "./utils/helpers";
import setHeader from "@/utils/auth.js";
import VueJwtDecode from "vue-jwt-decode";
import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'

import VResizable from 'v-resizable'
import { webAPI, crmAPI, lmsAPI } from "./utils/api";

Vue.use(excel)
Vue.use(VueExcelXlsx);
Vue.use(VResizable);

if (localStorage.getItem("user")) {
  var data = localStorage.getItem("user");
  let decoded = VueJwtDecode.decode(data);
  Vue.prototype.$decode_token = decoded;
  // setHeader(data)
} else {
  setHeader(false);
}

const plugins = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};
Vue.use(plugins);

Vue.prototype.$web_http = webAPI;
Vue.prototype.$crm_http = crmAPI;
Vue.prototype.$lms_http = lmsAPI;
Vue.config.devtools = true;

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY");
  }
});

Vue.filter('toMessageLog', function(value) {
  if (value === "DRAFT") {
    return "Transaksi terdaftar, user sekarang sedang menekan tombol daftar"
  } else if (value === "WAITING_FOR_PAYMENT") {
    return "Transaksi berhasil di buat, menunggu pemayaran dari user"
  } else if (value === "PAID") {
    return "Transaksi masuk, pembayaran biaya kelas berhasil dilakukan"
  } else {
    return "Transaksi sudah kadaluarsa"
  }
});

Vue.filter('toMessageStatus', function(value) {
  if (value === "DRAFT") {
    return "Draft"
  } else if (value === "WAITING_FOR_PAYMENT") {
    return "Menunggu Pembayaran"
  } else if (value === "PAID") {
    return "Sudah dibayar"
  } else {
    return "Kadaluarsa"
  }
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
