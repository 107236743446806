<template>
    
<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.75 7.3125C10.75 7.83027 10.3303 8.25 9.8125 8.25C9.29473 8.25 8.875 7.83027 8.875 7.3125C8.875 6.79473 9.29473 6.375 9.8125 6.375C10.3303 6.375 10.75 6.79473 10.75 7.3125Z" fill="currentColor"/>
<path d="M9.67334 9.23999L11.3352 10.3479L13.654 8.02904C13.7491 7.93392 13.8944 7.91035 14.0148 7.9705L16.375 9.18751V11.375C16.375 11.7202 16.0952 12 15.75 12H8.25C7.90482 12 7.625 11.7202 7.625 11.375V10.75C7.625 10.75 9.5494 9.15736 9.67334 9.23999Z" fill="currentColor"/>
<path d="M8.25 13.25C7.90482 13.25 7.625 13.5298 7.625 13.875C7.625 14.2202 7.90482 14.5 8.25 14.5H15.75C16.0952 14.5 16.375 14.2202 16.375 13.875C16.375 13.5298 16.0952 13.25 15.75 13.25H8.25Z" fill="currentColor"/>
<path d="M8.25 15.75C7.90482 15.75 7.625 16.0298 7.625 16.375C7.625 16.7202 7.90482 17 8.25 17H12C12.3452 17 12.625 16.7202 12.625 16.375C12.625 16.0298 12.3452 15.75 12 15.75H8.25Z" fill="currentColor"/>
<path d="M4.5 4.5C4.5 3.11929 5.61929 2 7 2H17C18.3807 2 19.5 3.11929 19.5 4.5V19.5C19.5 20.8807 18.3807 22 17 22H7C5.61929 22 4.5 20.8807 4.5 19.5V4.5ZM17 3.25H7C6.30964 3.25 5.75 3.80964 5.75 4.5V19.5C5.75 20.1904 6.30964 20.75 7 20.75H17C17.6904 20.75 18.25 20.1904 18.25 19.5V4.5C18.25 3.80964 17.6904 3.25 17 3.25Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "Article",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>