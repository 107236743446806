<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="back-icon" @click="$router.push('/career')">
      <round-arrow-back />
    </div>
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
        {{ name }}
      </h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Posisi</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.position"
            :rules="[!!form.position || 'Wajib diisi!']"
            class="form-input"
            placeholder="Posisi"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Perusahaan</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.company"
            :rules="[!!form.company || 'Wajib diisi!']"
            class="form-input"
            placeholder="Perusahaan"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Lokasi</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.location"
            :rules="[!!form.location || 'Wajib diisi!']"
            class="form-input"
            placeholder="Lokasi"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Logo</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.logo"
            :rules="[!!form.logo || 'Wajib diisi!']"
            class="form-input"
            placeholder="Logo"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form mt-5">
          <p>Tipe Kontrak</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="contract_type"
            v-model="form.contract_type"
            solo
            :item-text="'name'"
            :item-value="'value'"
          ></v-select>
        </div>
        <div class="content-form mt-5">
          <p>Tipe Pekerjaan</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="work_type"
            v-model="form.work_type"
            solo
            :item-text="'name'"
            :item-value="'value'"
          ></v-select>
        </div>
        <div class="content-form mt-5">
          <p>Level</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="level"
            v-model="form.level"
            solo
            :item-text="'name'"
            :item-value="'value'"
          ></v-select>
        </div>
        <div class="content-form">
          <p>Tanggal Berlangsung</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="5" class="pt-2"> Mulai </v-col>
                <v-col cols="6">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="date"
                    :rules="[!!form.started_at || 'Wajib diisi!']"
                    v-model="form.started_at"
                    class="form-input"
                    placeholder="00:00"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="6" class="pt-2"> Selesai </v-col>
                <v-col cols="6">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="date"
                    :rules="[!!form.closed_at || 'Wajib diisi!']"
                    v-model="form.closed_at"
                    class="form-input"
                    placeholder="00:00"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="spacing-ctn"></div>
      <div class="form-ctn">
        <p class="berkas-title">Career Detail</p>
        <div class="content-form">
          <p>Responsibilities</p>
          <div
            style="display: flex; gap: 10px; flex-wrap: wrap; margin: 10px 0"
          >
            <v-text-field
              :disabled="isWatch"
              v-for="(data, idx) in form.career_detail.responsibilities"
              :key="idx"
              outlined
              v-model="form.career_detail.responsibilities[idx]"
              class="form-input no-space"
              dense
              style="max-width: 290px"
            ></v-text-field>
            <Button
              name="+"
              width="40px"
              height="35px"
              title="Add Responsibility"
              v-if="!isWatch"
              @click="() => form.career_detail.responsibilities.push('')"
            />
            <Button
              name="-"
              width="40px"
              height="35px"
              title="Remove Responsibility"
              @click="
                () =>
                  form.career_detail.responsibilities.splice(
                    form.career_detail.responsibilities.length - 1,
                    1
                  )
              "
              outline
              v-if="form.career_detail.responsibilities.length && !isWatch"
            />
          </div>
        </div>
        <div class="content-form">
          <p>Requirements</p>
          <div
            style="display: flex; gap: 10px; flex-wrap: wrap; margin: 10px 0"
          >
            <v-text-field
              :disabled="isWatch"
              v-for="(data, idx) in form.career_detail.requirements"
              :key="idx"
              outlined
              v-model="form.career_detail.requirements[idx]"
              class="form-input no-space"
              dense
              style="max-width: 290px"
            ></v-text-field>
            <Button
              name="+"
              width="40px"
              height="35px"
              title="Add Requirement"
              v-if="!isWatch"
              @click="() => form.career_detail.requirements.push('')"
            />
            <Button
              name="-"
              width="40px"
              height="35px"
              title="Remove Requirement"
              @click="
                () =>
                  form.career_detail.requirements.splice(
                    form.career_detail.requirements.length - 1,
                    1
                  )
              "
              outline
              v-if="form.career_detail.requirements.length && !isWatch"
            />
          </div>
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button
          name="PUBLISH"
          width="260px"
          @click="onSumbmit"
          :disabled="!validData"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import RoundArrowBack from "../../../components/icons/RoundArrowBack.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormCareer",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      contract_type: [
        {
          name: "Full-time",
          value: "Full-Time",
        },
        {
          name: "Part-time",
          value: "Part-Time",
        },
        {
          name: "Internship",
          value: "Internship",
        },
      ],
      work_type: [
        {
          name: "Onsite",
          value: "Onsite",
        },
        {
          name: "Remote",
          value: "Remote",
        },
      ],
      level: [
        {
          name: "Junior",
          value: "Junior",
        },
        {
          name: "Senior",
          value: "Senior",
        },
      ],
      form: {
        position: "",
        company: "",
        location: "",
        contract_type: "Full-time",
        work_type: "Onsite",
        level: "Junior",
        logo: "",
        started_at: "",
        closed_at: "",
        career_detail: {
          career_id: "",
          responsibilities: [""],
          requirements: [""],
        },
      },
    };
  },
  components: {
    RoundArrowBack,
    // VueEditor,
    Button,
    Loading,
    Popup
  },
  computed: {
    validData() {
      return (
        this.form.position &&
        this.form.company &&
        this.form.location &&
        this.form.contract_type &&
        this.form.work_type &&
        this.form.level &&
        this.form.started_at &&
        this.form.closed_at &&
        this.form.career_detail.responsibilities[0] &&
        this.form.career_detail.requirements[0] 
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataCareer();
    },
    tidyUpDataFromGet(data) {
      const startedAt = new Date(data.career_detail.career.started_at);
      const closedAt = new Date(data.career_detail.career.closed_at);
      const formattedStartedAt = startedAt.toISOString().split("T")[0];
      const formattedClosedAt = closedAt.toISOString().split("T")[0];
      return {
        id: data.career_detail.career.id,
        position: data.career_detail.career.position,
        company: data.career_detail.career.company,
        location: data.career_detail.career.location,
        contract_type: data.career_detail.career.contract_type,
        work_type: data.career_detail.career.work_type,
        level: data.career_detail.career.level,
        started_at: formattedStartedAt,
        closed_at: formattedClosedAt,
        logo: data.career_detail.career.logo,
        career_detail: {
          responsibilities: JSON.parse(data.career_detail.responsibilities[0]),
          requirements: JSON.parse(data.career_detail.requirements[0]),
        },
      };
    },
    async getDataCareer() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/career-detail/" + this.id);
        const data = resp.data.data;
        this.form = this.tidyUpDataFromGet(data);
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          await this.$crm_http.put("/v1/career/" + this.form.id, this.form);
        } else {
          await this.$crm_http.post("/v1/career", this.form);
        }
        setTimeout(() => {
          this.$router.push("/career");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.no-space {
  margin-bottom: -20px;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
