<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah kamu yakin ingin menghapus career ini?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button name="YA, HAPUS DATA" width="100%" @click="onDelete" />
        <Button name="TIDAK, KEMBALI" width="100%" outline @click="() => (isModal = false)" />
      </div>
    </Modal>
    <div class="container-box">
      <h1>Career</h1>
      <div style="display: flex; justify-content: flex-end; margin-bottom: 30px">
        <Button icon="+" name="Buat Career" @click="() => $router.push('/career/new')" />
      </div>
      <v-row>
        <v-col>
          <v-select style="width: 37%" label="Urutkan dari" v-model="filter.sort_by" :items="sort_by" :item-text="'name'" :item-value="'value'" class="form-input" solo></v-select>
        </v-col>
        <v-col style="display: flex; justify-content: flex-end; gap: 10px">
          <!-- <Button name="Clear" outline @click="clearFilter" /> -->
          <Button name="Apply" @click="onFilter" />
        </v-col>
      </v-row>
    </div>
    <v-data-table :headers="headers" :items="careers" sort-by="calories" class="elevation-1 mt-10 table" hide-default-footer>
      <!-- <UpdateDeleteCareer v-slot:item.actions/> -->
      <template v-slot:item.is_active="{ item }">
        <v-chip :color="item.is_active ? 'green' : 'red'" dark>
          {{ item.is_active ? 'Aktif' : 'Non Aktif' }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="watchItem(item)"> mdi-eye </v-icon>
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination v-model="currentPage" class="my-4" :length="total" @input="getCareers"></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '../../../components/Button.vue';
import Modal from '../../../components/Modal.vue';
import moment from 'moment';
import Loading from '@/components/ilustration/Loading.vue';
import getAuthentification from '@/utils/badrequest.js';

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      isLoading: false,
      id: 0,
      isFilterActive: false,
      filter: {
        sort_by: 'desc',
      },
      sort_by: [
        {
          name: 'Terkecil',
          value: 'asc',
        },
        {
          name: 'Terbesar',
          value: 'desc',
        },
      ],
      headers: [
        { text: 'POSISI', value: 'position' },
        { text: 'PERUSAHAAN', value: 'company' },
        { text: 'TIPE KONTRAK', value: 'contract_type' },
        { text: 'TIPE PEKERJAAN', value: 'work_type' },
        { text: 'LEVEL', value: 'level' },
        { text: 'STATUS', value: 'is_active' },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      careers: [],
    };
  },
  components: {
    Button,
    Modal,
    Loading,
  },
  computed: {
    activeBtn() {
      return this.is_active;
    },
  },
  methods: {
    async getCareers() {
      await this.$crm_http
        .get(`/v1/career?sort_by=${this.filter.sort_by}&page=${this.currentPage}&per_page=10`)
        .then((response) => {
          this.careers = [];
          response.data.data.careers.forEach((element) => {
            this.careers.push({
              id: element.id,
              position: element.position,
              company: element.company,
              location: element.location,
              contract_type: element.contract_type,
              work_type: element.work_type,
              level: element.level,
              date: moment(element.started_at).format('DD MMM YYYY') + ' - ' + moment(element.closed_at).format('DD MMM YYYY'),
              is_active: element.is_active,
            });
          });
          const t = parseInt(response.data.data.total);
          this.total = Math.floor(t / 10 + (t % 10 === 0 ? 0 : 1));
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    onFilter() {
      this.currentPage = 1;
      this.isFilterActive = true;
      this.getCareers();
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        sort_by: '',
      };
      this.getCareers();
    },
    watchItem(item) {
      console.log(this.isLoading);
      this.$router.push('/career/watch/' + item.id);
    },
    editItem(item) {
      this.$router.push('/career/edit/' + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete('/v1/career/' + this.id, this.form);
        this.getCareers();
        this.isModal = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD');
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getCareers();
  },
};
</script>

<style scoped>
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
</style>
