<template>
  <div>
    <div v-if="!isBadRequest" class="template-1">
      <div class="template-left resizable" id="resizable-sidebar">
        <div class="resizer resizer-r" id="resizer"></div>
        <Navbar @updateSidebar="updateSidebar($event)"/>
      </div>
      <div class="template-right">
        <v-main>
          <router-view />
        </v-main>
      </div>
    </div>
    <router-view v-if="isBadRequest" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "MainTemplate",
  components: {
    Navbar,
  },
  data() {
    return {
      isBadRequest: false,
      full_name: "",
      isSidebarFull: false,
    };
  },
  methods: {
    updateSidebar({data, status}) {
      // Query the element
      const ele = document.getElementById("resizable-sidebar");
      const eleResizer = document.getElementById("resizer");
      // const right_sec = document.getElementById("right-sec");

      var isSidebarHidden = !status;

      if (data === 'Beranda') {
        isSidebarHidden = true;
        // right_sec.style.display = "none";
      }


      // The dimension of the element
      if (!isSidebarHidden) {
        ele.style.width = sessionStorage.getItem("sidebar-width");
        ele.style.minWidth = "350px";
        eleResizer.style.display = null;
      } else {
        ele.style.width = "75.87px";
        ele.style.minWidth = null;
        eleResizer.style.display = "none";
      }
    },
  },
  mounted() {
    // var data = JSON.parse(localStorage.getItem("user"))
    // if(data == null){
    //   this.isBadRequest = true
    // } else {
    //   this.full_name = data.full_name
    //   setHeader(data.token)
    // }
    // Query the element
    const ele = document.getElementById("resizable-sidebar");
    const eleResizer = document.getElementById("resizer");

    this.isSidebarFull = !(sessionStorage.getItem("sidebar") === "Beranda");

    // The current position of mouse
    let x = 0;

    // The dimension of the element
    if (this.isSidebarFull) {
      ele.style.width = sessionStorage.getItem("sidebar-width");
      ele.style.minWidth = "350px";
    } else {
      eleResizer.style.display = "none";
    }

    let w = 0;

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX;

      // Calculate the dimension of element
      const styles = window.getComputedStyle(ele);
      w = parseInt(styles.width, 10);

      // Attach the listeners to `document`
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - x;

      // Adjust the dimension of element
      ele.style.width = `${w + dx}px`;
    };

    const mouseUpHandler = function () {
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);

      sessionStorage.setItem("sidebar-width", ele.style.width);
    };

    // Query all resizers
    const resizers = ele.querySelectorAll(".resizer");

    // Loop over them
    [].forEach.call(resizers, function (resizer) {
      resizer.addEventListener("mousedown", mouseDownHandler);
    });
  },
};
</script>

<style scoped>
.template-1 {
  user-select: none;
  display: flex;
  height: 100vh;
}
.template-left {
  height: 100vh;
  /* resize: horizontal; */
  /* min-width: 350px; */
  overflow: auto;
}
.template-right {
  width: 100%;
  background: #f5f5f5;
  overflow-x: none;
  overflow-y: scroll;
}

.resizable {
  position: relative;
}

.resizer {
  /* All resizers are positioned absolutely inside the element */
  position: absolute;
}

/* Placed at the right side */
.resizer-r {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 5px;
}

/* Placed at the bottom side */
.resizer-b {
  bottom: 0;
  cursor: row-resize;
  height: 5px;
  left: 0;
  width: 100%;
}
</style>
