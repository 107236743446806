import moment from "moment";

export default {
  getDate(date) {
    return moment(date).format("DD MMM yyyy");
  },
  formatDate(date) {
    return moment(date).format("yyyy-MM-DD");
  },
  getTime(date) {
    return moment(date).format("HH:mm");
  },
  applyFormatFile() {
    return ".jpg,.jpeg,.png";
  },
  formatCurrency(amount, isDiscount) {
    if (typeof amount !== 'number') {
      return '';
    }
    if (isDiscount){
      return `-IDR ${amount.toLocaleString('id-ID')}`;
    }
    return `IDR ${amount.toLocaleString('id-ID')}`;
  },
  formatDateWithTimeAndTimeZone(date) {
    const formattedDate = moment(date).locale('id').format('DD MMMM YYYY');
    const formattedTime = moment(date).format('HH.mm');
    const timezone = moment(date).format('z');
    return `${formattedDate}, ${formattedTime} ${timezone}`;
},
  multipleChoices() {
    return [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
  },
  categoryClass(idx) {
    const category = [
      "technology",
      "bahasa",
      "soft skill",
      "prakerja",
      "public bootcamp",
    ];
    return category[idx - 1];
  },
  customToolBar() {
    return [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ header: 1 }, { header: 2 }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "formula"],
      [{ direction: "rtl" }],
      ["clean"],
    ];
  },
};
