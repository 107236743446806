<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C12.3452 7 12.625 7.27982 12.625 7.625V8.41747L13.3113 8.02123C13.6102 7.84865 13.9925 7.95107 14.1651 8.25C14.3376 8.54893 14.2352 8.93118 13.9363 9.10377L13.25 9.5L13.9363 9.89623C14.2352 10.0688 14.3377 10.4511 14.1651 10.75C13.9925 11.0489 13.6102 11.1514 13.3113 10.9788L12.625 10.5825V11.375C12.625 11.7202 12.3452 12 12 12C11.6548 12 11.375 11.7202 11.375 11.375V10.5825L10.6887 10.9788C10.3898 11.1514 10.0075 11.0489 9.83493 10.75C9.66234 10.4511 9.76476 10.0688 10.0637 9.89623L10.75 9.5L10.0637 9.10377C9.76477 8.93118 9.66235 8.54893 9.83494 8.25C10.0075 7.95107 10.3898 7.84865 10.6887 8.02123L11.375 8.41746V7.625C11.375 7.27982 11.6548 7 12 7ZM8.25 13.875C8.25 13.5298 8.52982 13.25 8.875 13.25H15.125C15.4702 13.25 15.75 13.5298 15.75 13.875C15.75 14.2202 15.4702 14.5 15.125 14.5H8.875C8.52982 14.5 8.25 14.2202 8.25 13.875ZM8.25 16.375C8.25 16.0298 8.52982 15.75 8.875 15.75H15.125C15.4702 15.75 15.75 16.0298 15.75 16.375C15.75 16.7202 15.4702 17 15.125 17H8.875C8.52982 17 8.25 16.7202 8.25 16.375Z"
 fill="currentColor"/>
<path d="M5.75 2H18.25C19.6307 2 20.75 3.11929 20.75 4.5V19.5C20.75 20.8807 19.6307 22 18.25 22H5.75C4.36929 22 3.25 20.8807 3.25 19.5V18.25H4.5V19.5C4.5 20.1904 5.05964 20.75 5.75 20.75H18.25C18.9404 20.75 19.5 20.1904 19.5 19.5V4.5C19.5 3.80964 18.9404 3.25 18.25 3.25H5.75C5.05964 3.25 4.5 3.80964 4.5 4.5V5.75H3.25V4.5C3.25 3.11929 4.36929 2 5.75 2Z" 
fill="currentColor"/>
<path d="M3.25 8.25V7.625C3.25 7.27982 3.52982 7 3.875 7C4.22018 7 4.5 7.27982 4.5 7.625V8.25H5.125C5.47018 8.25 5.75 8.52982 5.75 8.875C5.75 9.22018 5.47018 9.5 5.125 9.5H2.625C2.27982 9.5 2 9.22018 2 8.875C2 8.52982 2.27982 8.25 2.625 8.25H3.25Z" 
fill="currentColor"/>
<path d="M3.25 12V11.375C3.25 11.0298 3.52982 10.75 3.875 10.75C4.22018 10.75 4.5 11.0298 4.5 11.375V12H5.125C5.47018 12 5.75 12.2798 5.75 12.625C5.75 12.9702 5.47018 13.25 5.125 13.25H2.625C2.27982 13.25 2 12.9702 2 12.625C2 12.2798 2.27982 12 2.625 12H3.25Z" 
fill="currentColor"/>
<path d="M3.25 15.75V15.125C3.25 14.7798 3.52982 14.5 3.875 14.5C4.22018 14.5 4.5 14.7798 4.5 15.125V15.75H5.125C5.47018 15.75 5.75 16.0298 5.75 16.375C5.75 16.7202 5.47018 17 5.125 17H2.625C2.27982 17 2 16.7202 2 16.375C2 16.0298 2.27982 15.75 2.625 15.75H3.25Z" 
fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "EventIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
