<template>
  <div class="nav-menu">
    <div class="menu">
      <div class="left-sec">
        <v-container>
          <div class="logo-img">
            <img style="height: 35px" src="/img/site_logo.png" alt="Logo" />
          </div>
          <div class="menu-box">
            <div
              v-for="(item, index) in sidebarParent"
              :key="index"
              :class="`navigate-data ${
                sidebar === item.name ? 'navigate-active' : ''
              }`"
              v-on:click="item.disable ? null : toglerData(item.name)"
            >
              <div style="justify-content: center" class="d-flex">
                <component :is="item.component"></component>
              </div>
              <p>
                {{ item.name }}
              </p>
            </div>
          </div>
        </v-container>
        <div class="container">
          <!-- Bagian baru yang akan ditambahkan -->
          <div class="garis">
            <div class="navigate-3">
              <div class="d-flex ml-2">
                <garis />
              </div>
            </div>
          </div>

          <!-- Bagian logout -->
          <div
            class="profile profile-margin-right flex-column"
            style="position: absolute; bottom: 10px; margin-left: 10px"
          >
            <v-row class="profile-hover">
              <div
                class="logout-box"
                @click="logout"
                style="margin-left: -10px; margin-top: 10px; padding: 5px"
              >
                <LogOutIcon
                  class="logout-icon"
                  style="width: 10px; height: 10px"
                />
                <span style="font-size: 12px">Logout</span>
              </div>
            </v-row>
            <div
              style="
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 15px;
              "
            >
              <div
                class="profile-img"
                style="font-size: 15px; padding: 2px 0; margin-top: 5px"
              >
                AD
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-container
        v-if="isSidebarActive && sidebar != 'Beranda'"
        class="right-sec"
      >
        <div class="side-data">
          <h2>{{ sidebar == "Data" ? "Mater Data" : sidebar }}</h2>
          <div
            class="program"
            v-for="(data, idx) in sidebarParent.find(
              (item) => item.name === sidebar
            ).submenu"
            :key="idx"
          >
            <span>{{ data.name }}</span>
            <!-- opsi menu user tulis di sini -->
            <div
              v-for="(data2, idx2) in data.children"
              :key="idx2"
              class="navigate-3"
              :class="{ actived: layout === data2.name }"
              @click="toglerSubMenu(data2.name, data2.path)"
            >
              <div class="navigate-item d-flex ml-2">
                <component :is="data2.component"></component>
              </div>
              <div class="ml-2" style="font-size: 15px">
                <p>{{ data2.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import Home from "./icons/Home.vue";
import UserScheduleIcon from "./icons/UserScheduleIcon.vue";
import SertifikatIcon from "./icons/SertifikatIcon.vue";
import PresensiIcon from "./icons/PresensiIcon.vue";
import InputIcon from "./icons/InputIcon.vue";
import RiviewIcon from "./icons/RiviewIcon.vue";
import LogOutIcon from "./icons/LogOutIcon.vue";
import Garis from "./icons/Garis.vue";
import KelasLMS from "./icons/KelasLMS.vue";
import MainBanner from "./icons/MainBanner.vue";
import Event from "./icons/Event.vue";
import Voucher from "./icons/Voucher.vue";
import Class from "./icons/Class.vue";
import Section4 from "./icons/Section4.vue";
import Section3 from "./icons/Section3.vue";
import Section2 from "./icons/Section2.vue";
import Section1 from "./icons/Section1.vue";
import Career from "./icons/Career.vue";
import CareerApplication from './icons/CareerApplication.vue';
import Instructor from './icons/Instructor.vue';
import UserManagement from './icons/UserManagement.vue';
import Transaction from './icons/Transaction.vue';
import KampusMerdeka from './icons/KampusMerdeka.vue';
import Journal from './icons/Journal.vue';
import Schedule from './icons/Schedule.vue';
import Testimoni from './icons/Testimoni.vue';
import Survey from './icons/Survey.vue';
import Faq from './icons/Faq.vue';
import Article from './icons/Article.vue';
import Statistic from './icons/Statistic.vue';
import NonUser from './icons/NonUser.vue';
import User from './icons/User.vue';

export default {
  data() {
    return {
      layout: "Home",
      isHideKelasLMS: false,
      // isHideMasterData: false,
      isTransaction: false,
      isDataActive: false,
      isSidebarActive: false,
      sidebar: "Beranda",
      sidebarParent: [
        {
          name: "Beranda",
          component: Home,
          disable: false,
        },
        {
          name: "User",
          component: UserManagement,
          disable: false,
          submenu: [
            {
              name: "Program",
              children: [
                {
                  name: "Statistik",
                  component: Statistic,
                  path: '/statistic',
                },
                {
                  name: "User Aktif",
                  component: User,
                  path: '/user',
                },
                {
                  name: "User Tidak Aktif",
                  component: NonUser,
                  path: '/user-tidak-aktif',
                },
                {
                  name: "Corporate Registration",
                  component: KampusMerdeka,
                  path: "/corporate-registration",
                },
              ],
            },
          ],
        },
        {
          name: "Finance",
          component: Transaction,
          disable: false,
          submenu: [
          {
              name: "B2C",
              children: [
              {
                  name: "Public Training",
                  component: Section1,
                  path: "/transaction/b2b/public-training",
                },
                {
                  name: "Bootcamp",
                  component: Section2,
                  path: "/transaction/b2b/bootcamp",
                },
                {
                  name: "Event",
                  component: Section3,
                  path: "/transaction/b2b/event",
                },
              ],
            },
            {
              name: "B2B",
              children: [
                {
                  name: "Acceleration",
                  component: Section3,
                  path: '/transaction/b2b/acceleration',
                },
              ],
            },
            {
              name: 'B2G',
              children: [
                {
                  name: 'Prakerja',
                  component: Section4,
                  path: '/transaction/b2g/prakerja',
                },
              ],
            },
            {
              name: "LOG",
              children: [
                {
                  name: "Transaction Log",
                  component: Section4,
                  path: "/transaction/log/transaction_log",
                },
              ],
            },
          ],
        },
        {
          name: "LMS",
          component: KelasLMS,
          disable: false,
          submenu: [
            {
              name: "Program",
              children: [
                {
                  name: "User Schedule",
                  component: UserScheduleIcon,
                  path: "/user-schedule",
                },
                {
                  name: "Certificate",
                  component: SertifikatIcon,
                  path: "/certificate",
                },
              ],
            },
            {
              name: "Presensi",
              children: [
                {
                  name: "Presensi Peserta",
                  component: PresensiIcon,
                  path: "/presensi",
                },
              ],
            },
            {
              name: "Nilai",
              children: [
                {
                  name: "Input Nilai",
                  component: InputIcon,
                  path: "/assesment",
                },
                {
                  name: "Assessment Review",
                  component: RiviewIcon,
                  path: "/assessment-review",
                },
              ],
            },
          ],
        },
        {
          name: "Data",
          component: Transaction,
          disable: false,
          submenu: [
            {
              name: "Program",
              children: [
                {
                  name: "Kelas",
                  component: Class,
                  path: "/class-category",
                },
                {
                  name: "Kampus Merdeka",
                  component: KampusMerdeka,
                  path: "/kampus-merdeka",
                },
                {
                  name: "Event",
                  component: Event,
                  path: "/event-category",
                },
              ],
            },
            {
              name: "Main Power",
              children: [
                {
                  name: "Career",
                  component: Career,
                  path: "/career",
                },
                {
                  name: "Career Applicant",
                  component: CareerApplication,
                  path: "/career-application",
                },
              ]
            },
            {
              name: "Pembelajaran",
              children: [
                {
                  name: "Instructor",
                  component: Instructor,
                  path: "/instructor",
                },
                {
                  name: "Reflective Journal",
                  component: Journal,
                  path: "/reflective-journal",
                },
                {
                  name: "Schedule",
                  component: Schedule,
                  path: "/schedule",
                },
              ],
            },
            {
              name: "Promosi dan Informasi",
              children: [
                {
                  name: "Voucher",
                  component: Voucher,
                  path: "/voucher",
                },
                {
                  name: "Hit Banner",
                  component: MainBanner,
                  path: "/hit-banner",
                },
                {
                  name: "Main Banner",
                  component: MainBanner,
                  path: "/main-banner",
                },
                {
                  name: "Reporting Log",
                  component: Survey,
                  path: "/reporting-log",
                },
                {
                  name: "Reconciliation",
                  component: Survey,
                  path: "/reconciliation",
                },
                // {
                //   name: "Blast Email",
                //   component: Survey,
                //   path: "/blast-email",
                // },
                // {
                //   name: "Blast Report",
                //   component: Survey,
                //   path: "/blast-report",
                // },
              ],
            },
            {
              name: "Lainnya",
              children: [
                {
                  name: "Article",
                  component: Article,
                  path: "/blog",
                },
                {
                  name: "Testimoni",
                  component: Testimoni,
                  path: "/testimoni",
                },
                {
                  name: "Survey",
                  component: Survey,
                  path: "/survey",
                },
                {
                  name: "FAQ",
                  component: Faq,
                  path: "/faq",
                },
                {
                  name: "Privacy Policy",
                  component: Event,
                  path: "/privacy-policy",
                },
                {
                  name: "Partner",
                  component: Instructor,
                  path: "/partner",
                },
                {
                  name: "Logo Client",
                  component: Testimoni,
                  path: "/logo",
                },
                {
                  name: "Corporate Training",
                  component: Section1,
                  path: "/corporate-training",
                },
                {
                  name: "Project Portfolio",
                  component: Testimoni,
                  path: "/project-portfolio",
                },
                {
                  name: "Cerita Alumni",
                  component: Testimoni,
                  path: "/alumni-stories",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  components: {
    Home,
    Section1,
    Section2,
    Section3,
    Section4,
    UserManagement,
    KelasLMS,
    UserScheduleIcon,
    SertifikatIcon,
    PresensiIcon,
    InputIcon,
    RiviewIcon,
    Garis,
    // PresentasiPeserta,
    // Assesment,
    // Certificate,
    // MasterData,
    MainBanner,
    Event,
    Voucher,
    // Blog,
    Class,
    Career,
    CareerApplication,
    Instructor,
    // Transaction,
    KampusMerdeka,
    Journal,
    Schedule,
    Testimoni,
    Survey,
    Faq,
    Article,
    // // EventTransaction,
    // // BootcampTransaction,
    LogOutIcon,
    Statistic,
    NonUser,
    User,
  },

  methods: {
    logout() {
      localStorage.clear(); // Membersihkan semua data yang disimpan di localStorage
      sessionStorage.clear();
      this.$router.push("/login");
    },
    async checkingRole() {
      try {
        const resp = await this.$crm_http.get('/v1/user/me')
        const role = resp.data.data.user.user_type.name
        if (role === 'VISITOR') {
          this.sidebarParent.splice(1,4)
        } else if (role === 'FINANCE') {
          this.sidebarParent.splice(1, 1)
          this.sidebarParent.splice(2)
        } else if (role === 'MANAGEMENT') {
          this.sidebarParent.splice(1, 3)
        }
      } catch (error) {
        this.sidebarParent.splice(1);
      }
    },
    toglerData(data, status = null) {
      if (data === this.sidebar) {
        this.isSidebarActive = !this.isSidebarActive;
      } else {
        this.isSidebarActive = true;
        this.sidebar = data;
      }
      if (data === "Beranda") {
        this.isSidebarActive = false;

        this.layout = "Home";
        this.setLayoutSession("Home");
        this.$router.push("/home").catch(() => {});
      }

      if (status !== null) {
        this.isSidebarActive = status;
      }

      this.$emit("updateSidebar", { data, status: this.isSidebarActive });
      this.setSidebarSession(data, this.isSidebarActive);
    },

    toglerSubMenu(name, path) {
      this.layout = name;
      this.setLayoutSession(name);
      this.$router.push(path).catch(() => {});
    },
    setLayoutSession(name) {
      sessionStorage.setItem("layout", name);
    },
    getLayoutSession() {
      return sessionStorage.getItem("layout");
    },

    setSidebarSession(name, status) {
      sessionStorage.setItem("sidebar", name);
      sessionStorage.setItem("isSidebarActive", status);
      this.$forceUpdate();
    },
    getSidebarSession() {
      return sessionStorage.getItem("sidebar");
    },
    getSidebarStatusSession() {
      return sessionStorage.getItem("isSidebarActive");
    },
  },
  mounted() {
    this.layout = this.getLayoutSession() || "Home";
    const tempStatus = this.getSidebarStatusSession() || null;

    const temp = this.getSidebarSession() || "Beranda";

    this.toglerData(temp, tempStatus);
    this.checkingRole();
  },
};
</script>

<style scoped>
.profile-margin-right {
  margin-right: 20px;
}

.container {
  padding: 0 !important;
}

.program {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
}

.program :hover p {
  opacity: 60%;
}

.program p {
  margin: 0;
  color: gray;
}

.program span {
  color: darkgray;
}

.menu-box {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  /* padding: 1 2rem; */
  margin-bottom: 1rem;
}

.navigate-1 {
  display: flex;
  margin-top: 20px;
  margin-left: 4px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
}

.navigate-2 {
  display: flex;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  align-items: center;
}

.navigate-3 {
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #acafbf;
}

.navigate-3.actived p {
  color: #162ca2;
}

.actived {
  color: #162ca2 !important;
  font-weight: bolder;
}

.navigate-data {
  width: 100%;
  margin-top: 1rem;
  color: white;
  cursor: pointer;
  align-items: center;
  padding: 6px;
}

.navigate-data :hover {
  opacity: 0.6;
}

.navigate-data p {
  margin: 0;
  font-size: 10px;
  text-align: center;
  padding-top: 10px;
}

.nav-menu {
  height: 100%;
  /* padding: 30px 10px; */
  /* overflow-y: scroll; */
  /* overflow-x: hidden !important; */
}

.left-sec {
  width: 70px;
  background: #162ca2;
}

.right-sec {
  width: 280px;
  height: 100vh;
  overflow-y: scroll;
  margin-left: 0px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.right-sec::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.menu {
  display: flex;
  height: 100%;
}

.actived_img {
  color: #3a4bac !important;
  background: white;
  -webkit-filter: invert(100%);
}

.profile {
  display: flex;
  gap: 15px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: bolder;
  position: relative;
}

.profile-hover {
  display: none;
  cursor: pointer;
  position: absolute;
  top: -120px;
  /* Naik enam kali ke atas */
  left: 10px;
  width: 30px;
  /* Mengurangi lebar kotak */
  height: 30px;
  /* Menyesuaikan tinggi kotak */
  border-radius: 8px;
  /* Mengubah radius border */
  padding: 3px;
  /* Menambah padding */
  text-align: center;
  /* Pusatkan teks */
}

.profile-hover .logout-icon {
  width: 18px;
  /* Mengurangi ukuran ikon logout */
  height: 18px;
}

.profile-hover span {
  font-size: 8px;
  /* Mengurangi ukuran teks logout */
}

.profile-hover:hover {
  color: black;
}

.profile:hover .profile-hover {
  display: flex;
  flex-direction: column;
}

.profile-img {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #162ca2;
  font-weight: bolder;
  border-radius: 50%;
  font-size: 20px;
}

.profile-name {
  letter-spacing: 2px;
}

.navigate-active {
  border: none;
  width: 100%;
  background-color: white;
  /* color: black !important; */
  border-radius: 15px 0 0 15px;
  padding: 6px;
}

.navigate-active {
  color: rgb(35, 35, 255);
  font-weight: bolder;
}

.logo-img {
  display: flex;
  justify-content: center;
  margin-inline: 15px;
  padding-top: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
}

.side-data {
  margin-top: 40px;
  margin-inline: 30px;
  margin-bottom: 30px;
}

.profile-hover {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -80px;
  transform: translateY(-30%);
  width: 80%;
  border-radius: 18px;
}

.profile-hover:hover {
  color: black;
}

.profile:hover .profile-hover {
  display: flex;
}

.logout-icon {
  width: 20px;
  /* Mengubah lebar ikon */
  height: 20px;
  /* Mengubah tinggi ikon */
}

.logout-box {
  font-size: 12px;
  /* Mengubah ukuran teks Logout */
}

.profile-img {
  display: flex;
  flex-direction: row-reverse;
  /* Membalik arah baris */
  align-items: center;
  /* Pusatkan konten secara vertikal */
}

.profile-img img {
  height: 40px;
  /* Sesuaikan tinggi gambar sesuai kebutuhan */
  width: auto;
  /* Sesuaikan lebar gambar sesuai kebutuhan */
  margin-left: 10px;
  /* Beri jarak dari kiri untuk gambar */
}

.profile-name {
  color: black;
  /* Warna teks nama admin */
}

.profile {
  margin-right: 20px;
}

.logout-box {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-icon {
  height: 20px;
  /* Atur sesuai kebutuhan */
  margin-right: 10px;
}

.logout-box span {
  color: black;
  font-size: 16px;
  /* Atur sesuai kebutuhan */
}

.profile-hover {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -80px;
  transform: translateY(-30%);
  width: 80%;
  border-radius: 18px;
}

.profile-hover:hover {
  color: black;
}

.profile:hover .profile-hover {
  display: flex;
}

.logout-icon {
  width: 20px;
  /* Mengubah lebar ikon */
  height: 20px;
  /* Mengubah tinggi ikon */
}

.logout-box {
  font-size: 12px;
  /* Mengubah ukuran teks Logout */
}

.profile-img {
  display: flex;
  flex-direction: row-reverse;
  /* Membalik arah baris */
  align-items: center;
  /* Pusatkan konten secara vertikal */
}

.profile-img img {
  height: 40px;
  /* Sesuaikan tinggi gambar sesuai kebutuhan */
  width: auto;
  /* Sesuaikan lebar gambar sesuai kebutuhan */
  margin-left: 10px;
  /* Beri jarak dari kiri untuk gambar */
}

.profile-name {
  color: black;
  /* Warna teks nama admin */
}

.profile {
  margin-right: 20px;
}

.logout-box {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-icon {
  height: 20px;
  /* Atur sesuai kebutuhan */
  margin-right: 10px;
}

.logout-box span {
  color: black;
  font-size: 16px;
  /* Atur sesuai kebutuhan */
}
</style>
