<template>
    
<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.125 5.75C20.4702 5.75 20.75 6.02982 20.75 6.375V17.625C20.75 17.9702 20.4702 18.25 20.125 18.25H3.875C3.52982 18.25 3.25 17.9702 3.25 17.625V6.375C3.25 6.02982 3.52982 5.75 3.875 5.75H20.125ZM3.875 4.5C2.83947 4.5 2 5.33947 2 6.375V17.625C2 18.6605 2.83947 19.5 3.875 19.5H20.125C21.1605 19.5 22 18.6605 22 17.625V6.375C22 5.33947 21.1605 4.5 20.125 4.5H3.875Z" fill="currentColor"/>
<path d="M8.25 12C8.25 11.6548 8.52982 11.375 8.875 11.375H17.625C17.9702 11.375 18.25 11.6548 18.25 12C18.25 12.3452 17.9702 12.625 17.625 12.625H8.875C8.52982 12.625 8.25 12.3452 8.25 12Z" fill="currentColor"/>
<path d="M8.25 8.875C8.25 8.52982 8.52982 8.25 8.875 8.25H17.625C17.9702 8.25 18.25 8.52982 18.25 8.875C18.25 9.22018 17.9702 9.5 17.625 9.5H8.875C8.52982 9.5 8.25 9.22018 8.25 8.875Z" fill="currentColor"/>
<path d="M8.25 15.125C8.25 14.7798 8.52982 14.5 8.875 14.5H17.625C17.9702 14.5 18.25 14.7798 18.25 15.125C18.25 15.4702 17.9702 15.75 17.625 15.75H8.875C8.52982 15.75 8.25 15.4702 8.25 15.125Z" fill="currentColor"/>
<path d="M7 8.875C7 9.22018 6.72018 9.5 6.375 9.5C6.02982 9.5 5.75 9.22018 5.75 8.875C5.75 8.52982 6.02982 8.25 6.375 8.25C6.72018 8.25 7 8.52982 7 8.875Z" fill="currentColor"/>
<path d="M7 12C7 12.3452 6.72018 12.625 6.375 12.625C6.02982 12.625 5.75 12.3452 5.75 12C5.75 11.6548 6.02982 11.375 6.375 11.375C6.72018 11.375 7 11.6548 7 12Z" fill="currentColor"/>
<path d="M7 15.125C7 15.4702 6.72018 15.75 6.375 15.75C6.02982 15.75 5.75 15.4702 5.75 15.125C5.75 14.7798 6.02982 14.5 6.375 14.5C6.72018 14.5 7 14.7798 7 15.125Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "Faq",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};

</script>