<template>
  <!-- <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8934 10.6667H27.3334C27.687 10.6667 28.0261 10.5262 28.2762 10.2761C28.5262 10.0261 28.6667 9.68696 28.6667 9.33333V8C28.6667 6.93913 28.2453 5.92172 27.4951 5.17157C26.745 4.42143 25.7276 4 24.6667 4H7.33337C6.27251 4 5.25509 4.42143 4.50495 5.17157C3.7548 5.92172 3.33337 6.93913 3.33337 8V24C3.33337 25.0609 3.7548 26.0783 4.50495 26.8284C5.25509 27.5786 6.27251 28 7.33337 28H24.6667C25.7276 28 26.745 27.5786 27.4951 26.8284C28.2453 26.0783 28.6667 25.0609 28.6667 24V22.6667C28.6667 22.313 28.5262 21.9739 28.2762 21.7239C28.0261 21.4738 27.687 21.3333 27.3334 21.3333H22.6667C21.9203 21.3369 21.1815 21.1837 20.4981 20.8838C19.8146 20.5839 19.2017 20.1438 18.699 19.5921C18.1963 19.0404 17.815 18.3893 17.5798 17.681C17.3445 16.9727 17.2606 16.2228 17.3334 15.48C17.5012 14.1323 18.1623 12.8946 19.1891 12.0057C20.2159 11.1168 21.5355 10.6398 22.8934 10.6667ZM13.3334 8.66667H8.00004C7.82323 8.66667 7.65366 8.59643 7.52864 8.4714C7.40361 8.34638 7.33337 8.17681 7.33337 8C7.33337 7.82319 7.40361 7.65362 7.52864 7.5286C7.65366 7.40357 7.82323 7.33333 8.00004 7.33333H13.3334C13.5102 7.33333 13.6798 7.40357 13.8048 7.5286C13.9298 7.65362 14 7.82319 14 8C14 8.17681 13.9298 8.34638 13.8048 8.4714C13.6798 8.59643 13.5102 8.66667 13.3334 8.66667Z"
      fill="white"
    />
    <path
      d="M27.3334 12H22.6667C21.6059 12 20.5885 12.4214 19.8383 13.1716C19.0882 13.9217 18.6667 14.9391 18.6667 16C18.6667 17.0609 19.0882 18.0783 19.8383 18.8284C20.5885 19.5786 21.6059 20 22.6667 20H27.3334C27.687 20 28.0262 19.8595 28.2762 19.6095C28.5263 19.3594 28.6667 19.0203 28.6667 18.6667V13.3333C28.6667 12.9797 28.5263 12.6406 28.2762 12.3905C28.0262 12.1405 27.687 12 27.3334 12ZM22.6667 17.3333C22.403 17.3333 22.1453 17.2551 21.926 17.1086C21.7067 16.9621 21.5358 16.7539 21.4349 16.5102C21.334 16.2666 21.3076 15.9985 21.359 15.7399C21.4105 15.4812 21.5375 15.2437 21.7239 15.0572C21.9104 14.8707 22.148 14.7437 22.4066 14.6923C22.6653 14.6408 22.9334 14.6672 23.177 14.7682C23.4206 14.8691 23.6289 15.04 23.7754 15.2592C23.9219 15.4785 24.0001 15.7363 24.0001 16C24.0001 16.3536 23.8596 16.6928 23.6096 16.9428C23.3595 17.1929 23.0204 17.3333 22.6667 17.3333Z"
      fill="white"
    />
  </svg> -->
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.25 16.5H5.75C2.75 16.5 2 15.75 2 12.75V5.25C2 2.25 2.75 1.5 5.75 1.5H6.875C8 1.5 8.24752 1.83001 8.67502 2.40001L9.80002 3.90001C10.085 4.27501 10.25 4.5 11 4.5H13.25C16.25 4.5 17 5.25 17 8.25V9.75" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.8199 13.74C9.05742 13.8675 9.05742 16.4175 10.8199 16.545H14.9899C15.4924 16.545 15.9874 16.3575 16.3549 16.02C17.5924 14.94 16.9324 12.78 15.3049 12.5775C14.7199 9.06 9.63494 10.395 10.8349 13.7475" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "TransactionIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
