<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 13.3125C16.2259 13.3125 17.625 11.9134 17.625 10.1875C17.625 8.46162 16.2259 7.06251 14.5 7.06251C12.7741 7.06251 11.375 8.46162 11.375 10.1875C11.375 11.9134 12.7741 13.3125 14.5 13.3125Z" 
fill="currentColor"
/>
<path d="M4.5 3.25C3.11929 3.25 2 4.36929 2 5.75V17C2 18.3807 3.11929 19.5 4.5 19.5H19.5C20.8807 19.5 22 18.3807 22 17V5.75C22 4.36929 20.8807 3.25 19.5 3.25H4.5ZM3.25 5.75C3.25 5.05964 3.80964 4.5 4.5 4.5H7V7H3.25V5.75ZM8.25 18.25V4.5H19.5C20.1904 4.5 20.75 5.05964 20.75 5.75V17C20.75 17.3565 20.6008 17.6781 20.3614 17.9058C19.6893 16.3673 18.0283 14.5 14.5 14.5C10.7035 14.5 9.06904 16.662 8.50215 18.25H8.25ZM3.25 15.75H7V18.25H4.5C3.80964 18.25 3.25 17.6904 3.25 17V15.75ZM7 14.5H3.25V12H7V14.5ZM7 10.75H3.25V8.25H7V10.75Z" 
fill="currentColor"
/>
</svg>

</template>

<script>
export default {
  name: "Journal",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>