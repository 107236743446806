<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus testimoni ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>

    <div class="header">
      <h1>Testimoni</h1>

      <Button
        icon="+"
        name="Create Testimoni"
        outline
        style="background-color: #3b54d9; color: #ffffff; width: 210px"
        @click="() => $router.push('/testimoni/new')"
      />
    </div>

    <!-- <div class="container-box mt-10 filter-box">
      <div class="input-group">
        <span class="label">Cari</span>

        <div class="action-head">
          <div class="f-search">
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
            <input
              v-model="filter.category"
              type="text"
              class="f-input"
              placeholder="Cari berdasarkan kategori"
            />
          </div>
        </div>
      </div>

      <div class="input-group">
        <span class="label">Urutkan</span>

        <v-select
          label="Urutkan dari"
          v-model="filter.sortBy"
          :items="sortByList"
          :item-text="'name'"
          :item-value="'value'"
          hide-details="auto"
          class="dropdown"
          solo
        ></v-select>
      </div>

      <div class="input-group btn">
        <Button name="Terapkan Filter" width="200px" @click="getTestimoni" />
      </div>
    </div> -->

    <v-row>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            margin-top: 7px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="testimonies"
      class="elevation-1 mt-10 table"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.is_active="{ item }">
        <v-chip :color="item.is_active ? 'green' : 'red'" dark>
          {{ item.is_active ? "Aktif" : "Non Aktif" }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <div class="data-count" v-if="total">
      <span
        ><b
          >{{ pageNo(testimonies[testimonies.length - 1]) || 0 }} dari
          {{ total }} </b
        >data yang tertampil</span
      >
    </div>

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            total-visible="8"
            :length="totalPage"
            @input="getTestimoni"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import NewModal from "@/components/NewModal.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  data() {
    return {
      isModal: false,
      currentPage: 1,
      isLoading: false,
      total: 0,
      perPage: 10,
      totalPage: 0,
      message: "",
      isPopup: false,
      filter: {
        category: "",
        sortBy: "",
      },
      showFilterModal: false,
      filterModal: [
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
        {
          title: "Kategori",
          name: "category",
          items: [
            {
              name: "Prakerja",
              value: "PRAKERJA",
            },
            {
              name: "Kampus Merdeka",
              value: "KAMPUS_MERDEKA",
            },
            {
              name: "Corporate",
              value: "CORPORATE",
            },
            {
              name: "Acceleration",
              value: "ACCELERATION",
            },
            {
              name: "Training",
              value: "TRAINING",
            },
            {
              name: "Collaboration",
              value: "COLLABORATION",
            },
            {
              name: "Talent as a Service",
              value: "TALENT_SERVICE",
            },
          ],
        },
      ],
      id: 0,
      headers: [
        { text: "NO", value: "no" },
        { text: "NAMA", value: "name",width: "250" },
        { text: "UNIVERSITAS", value: "place", width: "150px"},
        { text: "MAJOR", value: "job", width: "200px" },
        { text: "KATEGORI", value: "category" },
        { text: "STATUS", value: "is_active" },
        { text: "AKSI", value: "actions", sortable: false, width: "132px" },
      ],
      testimonies: [],
    };
  },
  components: {
    Button,
    NewModal,
    Loading,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    FilterIcon,
    FilterModal,
    Popup
  },
  methods: {
    applyFilters() {
      this.getTestimoni();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getTestimoni() {
      await this.$crm_http
        .get(
          `/v1/testimony?page=${this.currentPage}&per_page=${
            this.perPage
          }&sort_by=${this.filter.sortBy}&category=${
            this.filter.category || ""
          }`
        )
        .then((response) => {
          let no = (this.currentPage - 1) * this.perPage + 1;
          this.testimonies = response.data.data.testimonies || [];
          this.testimonies = this.testimonies.map((item) => {
            item.no = no++;
            return item;
          });
          const t = parseInt(response.data.data.total);
          this.total = t;
          this.totalPage = Math.ceil(t / this.perPage);
          if (this.currentPage > this.totalPage) {
            this.currentPage = this.totalPage;
            this.getTestimoni();
          }
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    pageNo(item) {
      if (item) {
        return item.no;
      }
      return;
    },
    watchItem(item) {
      this.$router.push("/testimoni/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/testimoni/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete("/v1/testimony/" + this.id);
        this.isModal = false;
        this.isLoading = false;
        this.getTestimoni();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getTestimoni();
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group .label {
  font-size: larger;
  color: #000000;
}

.input-group.btn {
  margin-left: auto;
  justify-content: flex-end;
}

.dropdown {
  width: 70%;
  height: 7%;
  border-radius: 14px !important;
  background-color: transparent !important;
}

.action-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.data-count {
  height: 0;
  position: relative;
  overflow-y: visible;
  display: flex;
}

.data-count span {
  position: absolute;
  font-size: medium;
  top: 35px;
}
</style>
