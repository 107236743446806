<template>
  <svg :width="width" :height="height" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 12.555V3.50252C17 2.60252 16.265 1.93502 15.3725 2.01002H15.3275C13.7525 2.14502 11.36 2.94752 10.025 3.78752L9.8975 3.87002C9.68 4.00502 9.32 4.00502 9.1025 3.87002L8.915 3.75752C7.58 2.92502 5.195 2.13002 3.62 2.00252C2.7275 1.92752 2 2.60252 2 3.49502V12.555C2 13.275 2.585 13.95 3.305 14.04L3.5225 14.07C5.15 14.2875 7.6625 15.1125 9.1025 15.9L9.1325 15.915C9.335 16.0275 9.6575 16.0275 9.8525 15.915C11.2925 15.12 13.8125 14.2875 15.4475 14.07L15.695 14.04C16.415 13.95 17 13.275 17 12.555Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 4.11743V15.3674" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.3125 6.36743H4.625" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.875 8.61743H4.625" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: "KelasLMSIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
