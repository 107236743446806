<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5602 13.4683C8.33769 13.4683 4.73926 16.0892 4.73926 19.3099M11.5602 10.551C12.3322 10.5526 13.0874 10.3253 13.7302 9.89786C14.3731 9.47039 14.8749 8.86193 15.172 8.14938C15.3943 7.61656 15.4954 7.04102 15.4681 6.46435C15.4408 5.88768 15.2857 5.32427 15.0141 4.81483C14.7425 4.30539 14.3612 3.86263 13.8976 3.51853C13.434 3.17444 12.8998 2.93761 12.3336 2.82514C11.7675 2.71226 11.1834 2.7263 10.6234 2.86623C10.0634 3.00616 9.54144 3.26851 9.095 3.63439C8.64856 4.00028 8.2888 4.46059 8.04161 4.98219C7.79442 5.5038 7.66595 6.07372 7.66544 6.65093C7.66521 7.68429 8.07517 8.67548 8.80528 9.40676C9.53538 10.138 10.5259 10.5496 11.5593 10.551M12.3685 16.7247L13.8795 18.2366C13.9325 18.2907 13.9958 18.3336 14.0657 18.3629C14.1355 18.3922 14.2105 18.4073 14.2863 18.4073C14.362 18.4073 14.437 18.3922 14.5069 18.3629C14.5767 18.3336 14.6401 18.2907 14.6931 18.2366L17.7393 15.1905"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "User",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
